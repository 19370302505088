import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Container } from "react-bootstrap";
import Layout from "@layout";
import SEO from "@components/seo";
// import PageBreadcrumb from "../../components/pagebreadcrumb";
// import Profile from "../../components/Profile";
import { normalizedData } from "@utils/functions";
import jsonDataInit from "../../data/products/liability-insurance.json";
import axios from "axios";
import { useEffect, useState } from "react";
import Preload from "../../components/preloadPage/preload";
const Liability = ({ data, location, pageContext }) => {
    const [jsonData, setJsonData] = useState(jsonDataInit);
    const [preload, setPreload] = useState(true);
    useEffect(() => {
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a7312d7887159c5296dd07"
        )
            .then((resultData) => {
                setJsonData(resultData.data);
                setPreload(false);
            })
            .catch((err) => {
                console.log(err);
                setPreload(false);
            });
    }, []);
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            {" "}
            {preload && <Preload />}
            <SEO title="Liability Insurance" pathname="/" />
            <Container style={{ lineHeight: "130%" }}>
                <img
                    style={{
                        marginTop: "10px",
                        marginBottom: "30px",
                        borderRadius: "10px",
                    }}
                    src={jsonData[0].image}
                    alt="Liability"
                />
                <div
                    style={{
                        marginBottom: "30px",
                    }}
                >
                    <h3>{jsonData[0].title}</h3>
                    <h5>{jsonData[0].title1}</h5>
                    <p
                        style={{
                            textAlign: "justify",
                            color: "#000",
                            fontSize: "17px",
                        }}
                    >
                        {jsonData[0].title2}
                    </p>
                    <h5>{jsonData[0].text}</h5>
                    <p
                        style={{
                            textAlign: "justify",
                            color: "#000",
                            fontSize: "17px",
                        }}
                    >
                        {jsonData[0].text1}
                    </p>
                    <h5>{jsonData[0].text2}</h5>
                    <p
                        style={{
                            textAlign: "justify",
                            color: "#000",
                            fontSize: "17px",
                        }}
                    >
                        {jsonData[0].button}
                    </p>
                    <p style={{ color: "#000", fontSize: "18px" }}>
                        {jsonData[0].link}
                    </p>
                </div>
            </Container>
        </Layout>
    );
};
Liability.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query LiabilityQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        allArticle {
            nodes {
                ...Articles
            }
        }
    }
`;

export default Liability;
